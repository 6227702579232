<template>
  <div class="p-3">
    <appRightToWorkInAustralia
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appRightToWorkInAustralia>
  </div>
</template>
<script>
import appRightToWorkInAustralia from "../../components/checkRightToWorkInAustralia/RightToWorkInAustralia.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "RightToWorkInAustralia",

  components: {
    appRightToWorkInAustralia,
  },
  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
};
</script>